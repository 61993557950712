<template>
  <div style="height: 100%; width: 100%;">
    <div
      v-if="loadingMarkers"
      class="d-flex justify-content-center align-items-center"
      style="height: 100%"
    >
      <b-spinner variant="success" label="Spinning"></b-spinner>
    </div>

    <l-map
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      @update:center="centerUpdate"
      @update:zoom="zoomUpdate"
      v-else
    >
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-marker
        v-for="(marker, index) in marcadores"
        :key="index"
        :lat-lng="marker.localizacao"
      >
        <l-popup>
          <div>
            {{ marker.nome }}: {{ marker.count }}
            {{
              marker.count > 1
                ? $t("geral.labels.estabelecimentos")
                : $t("geral.labels.estabelecimento")
            }}
            <!-- <template
              v-if="marker.count > 1"
              >s
            </template
            > -->
          </div>
        </l-popup>
      </l-marker>
    </l-map>
    <div
      v-if="loadingScroll"
      class="d-flex justify-content-center align-items-center"
    >
      <b-spinner variant="success" label="Spinning"></b-spinner>
    </div>
  </div>
</template>

<script>
import { latLng } from "leaflet";
import { Icon } from "leaflet";
import Service from "../../services/ApiServices";

import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("@/assets/icons/icon-pin.svg"),
  iconUrl: require("@/assets/icons/icon-pin.svg"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  iconSize: [30, 40]
});
export default {
  name: "MapLeaflet",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup
  },
  data() {
    return {
      loadingScroll: true,
      loadingMarkers: true,
      cidadesGet: [],
      groupByState: [],
      zoom: 4,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: null,
      currentCenter: latLng(-4.55826, -37.7679),
      mapOptions: {
        zoomSnap: 0.5
      },
      allEstablishment: [],
      groupByCity: []
    };
  },
  computed: {
    center() {
      //Brasil
      if (localStorage.adminnastro) return latLng(-12.812517, -50.08875);
      //Bahia
      else if (localStorage.partneraccess == "5e8099266bac487f3cf0ef5c")
        return latLng(-13.064756, -42.279906);
      //Ceará
      else if (localStorage.partneraccess == "5e8099266bac487f3cf0ef56")
        return latLng(-4.735201, -41.143799);
      //Paraíba
      else if (localStorage.partneraccess == "5e8099266bac487f3cf0ef58")
        return latLng(-7.185823, -36.561707);
      //Juazeiro do Norte
      else if (localStorage.partneraccess == "5e80cfa915b7f33f89d35036")
        return latLng(-7.1856939, -39.3504161);
      //Aracati
      else if (localStorage.partneraccess == "5e80cfa915b7f33f89d3471f")
        return latLng(-4.5658241, -37.7864217);
      //Barbalha
      else if (localStorage.partneraccess == "5e80cfa915b7f33f89d347e8")
        return latLng(-7.2982, -39.3021);
      //uberaba
      else if (localStorage.partneraccess == "5e80cfa915b7f33f89d35ae2")
        return latLng(-19.7534096, -48.019574);
      //Jericoacoara
      else if (localStorage.partneraccess == "5e80cfa915b7f33f89d3500a")
        return latLng(-2.795762, -40.5209241);
      else return latLng(-12.812517, -50.08875);
    },
    marcadores() {
      if (this.currentZoom >= 5.5) return this.groupByCity;
      return this.groupByState;
    }
  },
  methods: {
    getGroupedCidades() {
      const request = new Service();
      request.getAll({}, "mapas/GroupedCidades").then(res => {
        this.groupByCity = res;
        this.loadingMarkers = false;
      });
    },
    getGroupedEstados() {
      const request = new Service();
      request.getAll({}, "mapas/GroupedEstados").then(res => {
        this.groupByState = res;
      });
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    getPartnerZoom() {
      //Brasil
      if (localStorage.adminnastro) return 4;
      //Bahia
      else if (localStorage.partneraccess == "5e8099266bac487f3cf0ef5c")
        return 6.5;
      //Ceará
      else if (localStorage.partneraccess == "5e8099266bac487f3cf0ef56")
        return 7;
      //Paraíba
      else if (localStorage.partneraccess == "5e8099266bac487f3cf0ef58")
        return 8;
      //Juazeiro do Norte
      else if (localStorage.partneraccess == "5e80cfa915b7f33f89d35036")
        return 12;
      //Aracati
      else if (localStorage.partneraccess == "5e80cfa915b7f33f89d3471f")
        return 12;
      //Barbalha
      else if (localStorage.partneraccess == "5e80cfa915b7f33f89d347e8")
        return 12;
      //Uberaba
      else if (localStorage.partneraccess == "5e80cfa915b7f33f89d35ae2")
        return 12;
        //Jericoacoara
      else if (localStorage.partneraccess == "5e80cfa915b7f33f89d3500a")
        return 11;
      else return 4;
    }
  },
  async mounted() {
    this.loadingScroll = await true;
    await this.getGroupedEstados();
    this.loadingScroll = await false;
    await this.getGroupedCidades();
    this.zoom = this.getPartnerZoom();
  }
};
</script>

<template>
  <section class="servicos-page">
    <!-- <div
      v-if="false"
      class="servicos-page-filter"
      style="box-shadow: 5px 5px 15px 0 rgba(44, 44, 44, 0.15); z-index: 401;"
      :class="{ teste: $sidebar.showSidebar }"
    >
      FILTROS
      <hr class="hr" />
      <b-form-group label="Estado" label-size="sm" label-for="input-estado">
        <v-select
          id="input-estado"
          v-model="estadoFilter"
          :options="estados"
          label="name"
          placeholder="Selecione..."
          @input="changedEstado()"
        ></v-select>
      </b-form-group>
      <b-form-group label="Cidade" label-size="sm" label-for="input-1">
        <v-select
          id="input-1"
          v-model="cidadeFilter"
          :options="cidades"
          label="nome"
          placeholder="Selecione..."
          :disabled="!cidades.length > 0"
        ></v-select>
      </b-form-group>
      <b-form-group
        label="Categoria do Estabelecimento"
        label-size="sm"
        label-for="input-2"
      >
        <v-select
          id="input-2"
          v-model="tiposEstabelecimentoFilter"
          :options="tiposEstabelecimento"
          placeholder="Selecione..."
          label="nome"
        ></v-select>
      </b-form-group>
      <b-form-group
        label="Dias de funcionamento"
        label-size="sm"
        label-for="input-3"
      >
        <v-select
          id="input-3"
          v-model="diasFuncionamentoFilter"
          :options="dias"
          placeholder="Selecione..."
          label="text"
        ></v-select>
      </b-form-group>
      <b-form-checkbox class="mb-4 mt-4" v-model="isDeliveryFilter">
        Delivery
      </b-form-checkbox>
      <b-form-checkbox v-model="isPermiteRetiradaFilter">
        Retirada
      </b-form-checkbox>
      <hr />
      <b-button block variant="success" @click="resetForm"
        >Limpar Filtros</b-button
      >
    </div> -->
    <div
      class="servicos-page-content"
      style="padding: 0; height: calc(100vh - 86px);"
    >
      <!-- <b-input-group>
        <b-form-input
          v-model.trim="search"
          placeholder="O que está procurando?"
          v-debounce:500ms="getEmpresas"
        ></b-form-input>
        <b-input-group-append>
          <b-button
            size="sm"
            text="Button"
            variant="light"
            @click="getEmpresas"
          >
            <b-icon-search />
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <div class="row  justify-content-start">
        <div class="d-flex align-items-start flex-column">
          <div class="ml-2 p-2">Resultados: {{ totalResultado }}</div>
        </div>
      </div> -->
      <!-- <div
        v-if="loading"
        class="d-flex justify-content-center align-items-center"
        style="height: 100%"
      >
        <b-spinner variant="success" label="Spinning"></b-spinner>
      </div> -->
      <!-- <div
        v-if="empresas.length === 0"
        class="d-flex flex-column justify-content-center align-items-center"
        style="height: 100%"
      >
        <h2>{{ $t("geral.mensagens.nao-ha-dados") }}</h2>
        <p style="text-align: center;">
          {{ $t("geral.mensagens.pesquise-outro-termo-ou-cadastre") }}
        </p>
      </div> -->
      <mapa />
    </div>
  </section>
</template>

<script>
import Mapa from "./mapa/MapLeaflet";
import Service from "../services/ApiServices";
export default {
  components: {
    Mapa
  },
  data() {
    return {
      page: 0,
      size: 12,
      estados: [],
      cidades: [],
      empresas: [],
      tiposEstabelecimento: [],
      search: "",
      isLast: false,
      loadingScroll: false,
      loading: true,
      cidadeFilter: "",
      estadoFilter: "",
      tiposEstabelecimentoFilter: "",
      diasFuncionamentoFilter: "",
      isDeliveryFilter: "",
      isPermiteRetiradaFilter: "",
      totalResultado: 0,
      dias: [
        { value: "DOMINGO", text: "Domingo" },
        { value: "SEGUNDA_FEIRA", text: "Segunda-feira" },
        { value: "TERCA_FEIRA", text: "Terça-feira" },
        { value: "QUARTA_FEIRA", text: "Quarta-feira" },
        { value: "QUINTA_FEIRA", text: "Quinta-feira" },
        { value: "SEXTA_FEIRA", text: "Sexta-feira" },
        { value: "SABADO", text: "Sábado" }
      ]
    };
  },
  watch: {
    cidadeFilter: function() {
      this.getEmpresas();
    },
    tiposEstabelecimentoFilter: function() {
      this.getEmpresas();
    },
    diasFuncionamentoFilter: function() {
      this.getEmpresas();
    },
    isDeliveryFilter: function() {
      this.getEmpresas();
    },
    isPermiteRetiradaFilter: function() {
      this.getEmpresas();
    }
  },
  methods: {
    loadMore() {
      if (this.isLast == false) {
        this.page = this.page + 1;
        const request = new Service();
        this.loadingScroll = true;
        request
          .findByParams(this.queryFilter, "empresas/findByParams")
          .then(res => {
            this.isLast = res.last;
            this.empresas = this.empresas.concat(...res.content);
            this.loadingScroll = false;
            this.totalResultado = res.totalElements;
          });
      }
    },
    getEmpresas() {
      this.loading = true;
      this.page = 0;
      const request = new Service();
      request
        .findByParams(this.queryFilter, "empresas/findByParams")
        .then(res => {
          this.isLast = res.last;
          this.empresas = res.content;
          this.loading = false;
          this.totalResultado = res.totalElements;
        });
    },
    verifyExistsProperty(obj, property) {
      return Object.prototype.hasOwnProperty.call(obj, property);
    },
    IsEmpty(str) {
      return str === "";
    },
    resetForm() {
      this.cidadeFilter = "";
      this.tiposEstabelecimentoFilter = "";
      this.diasFuncionamentoFilter = "";
      this.isDeliveryFilter = "";
      this.isPermiteRetiradaFilter = "";
    },
    getEstados() {
      const request = new Service();
      request
        .getAll({ sort: "nome", size: 27 }, "estados")
        .then(res => (this.estados = res.content));
    },
    changedEstado() {
      this.cidades = [];
      if (this.estadoFilter == null) this.estadoFilter = "";
      this.cidadeFilter = "";
      this.getEmpresas();
      this.getCidades(this.estadoFilter);
    },
    getCidades(estado) {
      const request = new Service();
      if (estado && estado.name)
        request
          .getAll(
            { sort: "nome", nomeEstado: estado.name },
            "cidades/findByEstado"
          )
          .then(res => (this.cidades = res.content));
    }
  },
  computed: {
    tiposEstabelecimentoSearch() {
      return this.tiposEstabelecimentoFilter !== "" &&
        this.tiposEstabelecimentoFilter !== null
        ? this.tiposEstabelecimentoFilter.nome
        : "";
    },
    estadoSearch() {
      return this.estadoFilter !== "" &&
        this.estadoFilter !== null &&
        this.estadoFilter != undefined &&
        this.estadoFilter.name
        ? this.estadoFilter.name
        : "";
    },
    cidadeSearch() {
      return this.cidadeFilter !== "" &&
        this.cidadeFilter !== null &&
        this.cidadeFilter != undefined &&
        this.cidadeFilter.nome
        ? this.cidadeFilter.nome
        : "";
    },
    diasFuncionamentoSearch() {
      return this.diasFuncionamentoFilter !== "" &&
        this.diasFuncionamentoFilter !== null
        ? this.diasFuncionamentoFilter.value
        : "";
    },
    isDeliverySearch() {
      return this.isDeliveryFilter ? this.isDeliveryFilter : "";
    },
    isPermiteRetiradaSearch() {
      return this.isPermiteRetiradaFilter ? this.isPermiteRetiradaFilter : "";
    },
    queryFilter() {
      return {
        tipoEstabelecimento: this.tiposEstabelecimentoSearch,
        diasFuncionamento: this.diasFuncionamentoSearch,
        isDelivery: this.isDeliverySearch,
        isPermiteRetirada: this.isPermiteRetiradaSearch,
        cidade: this.cidadeSearch,
        estado: this.estadoSearch,
        search: this.search,
        page: this.page,
        size: this.size
      };
    }
  },
  mounted() {
    // const request = new Service();
    // this.search = this.verifyExistsProperty(this.$route.params, "search")
    //   ? this.$route.params.search
    //   : "";
    // if (
    //   this.verifyExistsProperty(
    //     this.$route.params,
    //     "cidadeFilter" && this.$route.params.cidadeFilter != ""
    //   )
    // ) {
    //   this.estadoFilter = this.$route.params.cidadeFilter.state
    //     ? this.$route.params.cidadeFilter.state
    //     : "";
    // } else if (this.verifyExistsProperty(this.$route.params, "estadoFilter")) {
    //   this.estadoFilter = this.$route.params.estadoFilter
    //     ? this.$route.params.estadoFilter
    //     : "";
    // }
    // this.cidadeFilter = this.verifyExistsProperty(
    //   this.$route.params,
    //   "cidadeFilter"
    // )
    //   ? this.$route.params.cidadeFilter
    //   : "";
    // this.tiposEstabelecimentoFilter = this.verifyExistsProperty(
    //   this.$route.params,
    //   "tipoEstabelecimento"
    // )
    //   ? this.$route.params.tipoEstabelecimento
    //   : "";
    // request
    //   .getAll({ sort: "nome", size: 50 }, "tiposEstabelecimento")
    //   .then(res => {
    //     this.tiposEstabelecimento = res.content;
    //   });
    // request
    //   .findByParams(this.queryFilter, "empresas/findByParams")
    //   .then(res => {
    //     this.empresas = res.content;
    //     this.loading = false;
    //     this.totalResultado = res.totalElements;
    //   });
    // this.getEstados();
    // this.getCidades(this.estadoFilter);
    // setTimeout(() => {
    //   this.loading = false;
    // }, 5000)
  }
};
</script>
